<template>
  <div class="home">
    <div class="title">
      <span>个人中心</span>
      <a href="javascript:;" @click="goLecturer">讲师后台</a>
    </div>
    <div class="content">
      <div class="left">
        <el-menu
            class="el-menu-vertical-demo"
            background-color="#6EA6F6"
            text-color="#fff"
            active-text-color="#ffd04b"
            router
            :default-active="$route.path"
          >
            <!-- <el-submenu index="1">
              <template slot="title">
                <span>财富中心</span>
              </template>
              <el-menu-item popper-class="item_class" index="/rightMain/rightMain/treasure">财富信息</el-menu-item>
              <el-menu-item index="/rightMain/rightMain/consumption">消费记录</el-menu-item>
            </el-submenu> -->
            <el-submenu index="2">
              <template slot="title">
                <span>安全中心</span>
              </template>
              <el-menu-item index="/rightMain/rightMain/pwd">修改密码</el-menu-item>
              <el-menu-item index="/rightMain/rightMain/bindPhone">绑定手机</el-menu-item>
              <el-menu-item index="/billing/flower">财富信息</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>我的订单</span>
              </template>
              <el-menu-item index="/myOrder/tjOrder">投教订单</el-menu-item>
            </el-submenu>
          </el-menu>
      </div>
      <div class="right">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data(){
    return{}
  },
  created() {
    if(window._user == null || window._user.id == null || window._user.id == -1){
        // location.href = this.allFn.getUrlVerify('//user.ggcj.com/login/login_web.html?sUrl=//user.ggcj.com')
    }
  },
  methods: {
    goLecturer(){
      window.open(this.allFn.getUrlVerify('//user.ggcj.com/user_lecturer'))
    }
  },
};
</script>
<style lang="scss">
html,body{
  margin:0;
  padding:0;
}
.title{
  height:45px;
  width:1200px;
  margin:5px auto;
  font-size:24px;
  line-height:45px;
  background: url(../images/title_bottom.jpg) no-repeat left bottom;
  background-size:1200px 1px;
  font-size:18px;
  color:#333333;
  span{
    float:left;
    margin-left:20px;
  }
  a{
    float:right;
    margin-right:10px;
    color:#333333;
    font-size:16px;
  }
  a:hover{
    color:#ce0000
  }
}
.home {
  background-color: #e1e1e1;
}
.content{
  width:1200px;
  margin:0 auto;
  display:flex;
  display: -webkit-flex;
  flex-direction:row;
  flex-wrap:nowrap;
}
.left{
  width:200px;
  padding:5px 0;
}
.right{
  width:1000px;
  padding:5px 0 5px 40px;
}
.el-menu{
  margin:0;
}
.el-submenu__title {
  height: 48px;
  text-align: center;
  line-height: 48px;
}
.el-menu-item {
  height: 48px;
  text-align: center;
  line-height: 48px;
}
.item_class{
  background: #fff;
}
</style>
